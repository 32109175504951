var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    staticClass: "mb-4"
  }, [_c('p', [_vm._v("This allows bidders to provide the bid price.")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Search Items ..."
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchHandler.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searching,
      callback: function ($$v) {
        _vm.searching = $$v;
      },
      expression: "searching"
    }
  }, [_vm.searching ? _c('el-button', {
    attrs: {
      "slot": "append",
      "icon": "el-icon-close"
    },
    on: {
      "click": _vm.clearSearch
    },
    slot: "append"
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "col-md-6 text-right"
  }, [_vm.isClosed ? _c('el-dropdown', [_c('el-button', {
    staticClass: "ml-2",
    attrs: {
      "size": "small",
      "trigger": "click"
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "microsoft-excel",
      "size": 18
    }
  }), _vm._v(" Download "), _c('mdicon', {
    attrs: {
      "name": "menu-down",
      "size": 20
    }
  })], 1), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    nativeOn: {
      "click": function ($event) {
        return _vm.awardDownload('accepted', 1);
      }
    }
  }, [_vm._v(" Currency Formatted ")]), _c('el-dropdown-item', {
    nativeOn: {
      "click": function ($event) {
        return _vm.awardDownload('full', 1);
      }
    }
  }, [_vm._v(" Currency Formatted Full Result ")]), _c('el-dropdown-item', {
    nativeOn: {
      "click": function ($event) {
        return _vm.awardDownload('accepted', 0);
      }
    }
  }, [_vm._v(" Unformatted ")]), _c('el-dropdown-item', {
    nativeOn: {
      "click": function ($event) {
        return _vm.awardDownload('full', 0);
      }
    }
  }, [_vm._v(" Unformatted Full Result ")])], 1)], 1) : _vm._e()], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 pt-2"
  }, _vm._l(_vm.tableDataBids, function (product, index) {
    return _c('div', {
      key: index,
      staticClass: "mb-3"
    }, [_c('el-card', {
      key: index,
      staticClass: "box-card",
      attrs: {
        "shadow": "never"
      }
    }, [_c('div', {
      staticClass: "clearfix"
    }, [_c('div', {
      staticClass: "mt-2",
      staticStyle: {
        "float": "left"
      }
    }, [_c('strong', {
      domProps: {
        "innerHTML": _vm._s(product.requested_product_name_list_mode != '' ? product.requested_product_name_list_mode : product === null || product === void 0 ? void 0 : product.requested_product_name)
      }
    }), _c('br'), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.min_shelf_life_months) + " ")]), _vm._v(_vm._s(product.min_shelf_life_months) + " month(s) | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.unit_quantity))]), _vm._v(" " + _vm._s(product.unit_quantity) + " "), _vm.formatMinMax(product.bid_min, product.bid_max) ? _c('span', [_vm._v("| "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(" Min/Max Single Item Price ")]), _vm._v(" " + _vm._s(_vm.formatMinMax(product.bid_min, product.bid_max)))]) : _vm._e(), !_vm.isFuture ? _c('a', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'collapse-' + index,
        expression: "'collapse-' + index"
      }],
      attrs: {
        "aria-expanded": "true",
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('mdicon', {
      staticClass: "when-open",
      attrs: {
        "name": "chevron-up",
        "size": 20
      }
    }), _c('mdicon', {
      staticClass: "when-closed",
      attrs: {
        "name": "chevron-down",
        "size": 20
      }
    })], 1) : _vm._e()]), !_vm.isFuture ? _c('div', {
      staticClass: "mt-2 pt-2",
      staticStyle: {
        "float": "right"
      }
    }, [_vm.isCurrent ? _c('el-button', {
      directives: [{
        name: "permission",
        rawName: "v-permission",
        value: 'user-edit',
        expression: "'user-edit'"
      }],
      attrs: {
        "type": "primary",
        "size": "small"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.handleProductEdit(null, product);
        }
      }
    }, [_c('span', [_vm._v("+ Add Bid")])]) : _vm._e()], 1) : _vm._e()])]), _c('b-collapse', {
      staticClass: "mt-2",
      attrs: {
        "visible": "",
        "id": 'collapse-' + index
      }
    }, [_c('el-card', {
      key: index,
      staticClass: "box-card",
      attrs: {
        "shadow": "never"
      }
    }, [_c('el-table', {
      key: index + _vm.tableKey,
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "data": product.bids,
        "row-class-name": _vm.tableRowClassName
      }
    }, [_vm._l(_vm.columns, function (column, index) {
      return _c('el-table-column', {
        key: index,
        attrs: {
          "sortable": column.sortable,
          "label": column ? column.label : column,
          "align": column ? column.align : column,
          "min-width": column.minwidth,
          "prop": index,
          "formatter": typeof column.formatter === 'function' ? column.formatter : null
        }
      }, [_c('template', {
        slot: "header"
      }, [_vm._v(" " + _vm._s(column.label) + " "), column.tip ? _c('el-tooltip', {
        attrs: {
          "content": column.tip,
          "placement": "top"
        }
      }, [_c('i', {
        staticClass: "el-tooltip el-icon-info"
      })]) : _vm._e()], 1)], 2);
    }), _c('el-table-column', {
      attrs: {
        "label": "",
        "min-width": "170"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_c('el-button-group', [_vm.isCurrent ? _c('el-button', {
            directives: [{
              name: "permission",
              rawName: "v-permission",
              value: 'user-edit',
              expression: "'user-edit'"
            }],
            attrs: {
              "type": "info",
              "size": "small"
            },
            on: {
              "click": function ($event) {
                $event.preventDefault();
                return _vm.handleProductEdit(scope.row, product);
              }
            }
          }, [_c('span', [_vm._v("View Bid")])]) : _vm._e(), _vm.isCurrent ? _c('el-button', {
            directives: [{
              name: "permission",
              rawName: "v-permission",
              value: 'user-edit',
              expression: "'user-edit'"
            }],
            attrs: {
              "type": "danger",
              "size": "small"
            },
            on: {
              "click": function ($event) {
                $event.preventDefault();
                return _vm.handleProductDelete(scope.row, product);
              }
            }
          }, [_c('span', [_vm._v("Delete")])]) : _vm._e()], 1)];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "width": "30"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          var _scope$row, _scope$row$raw, _scope$row2, _scope$row2$raw;
          return [scope && scope.row && scope.row.raw && scope.row.raw.comment && (scope === null || scope === void 0 ? void 0 : (_scope$row = scope.row) === null || _scope$row === void 0 ? void 0 : (_scope$row$raw = _scope$row.raw) === null || _scope$row$raw === void 0 ? void 0 : _scope$row$raw.comment) != '' ? _c('el-popover', {
            attrs: {
              "trigger": "hover",
              "content": scope === null || scope === void 0 ? void 0 : (_scope$row2 = scope.row) === null || _scope$row2 === void 0 ? void 0 : (_scope$row2$raw = _scope$row2.raw) === null || _scope$row2$raw === void 0 ? void 0 : _scope$row2$raw.comment
            },
            scopedSlots: _vm._u([{
              key: "reference",
              fn: function () {
                return [_c('mdicon', {
                  attrs: {
                    "name": "comment-outline",
                    "size": 18
                  }
                })];
              },
              proxy: true
            }], null, true)
          }) : _vm._e()];
        }
      }], null, true)
    })], 2)], 1)], 1)], 1);
  }), 0)]), _c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "total, prev, pager, next, jumper",
      "hide-on-single-page": true,
      "current-page": _vm.currentPage,
      "page-size": _vm.pageSize,
      "total": _vm.currentTotalPage
    },
    on: {
      "current-change": _vm.SetPageNumber
    }
  })], 1), _c('div', {
    staticClass: "rft-active-product-modal"
  }, [_c('RftEditActiveProductModal', {
    ref: "RftEditActiveProductModal"
  })], 1), _c('div', {
    staticClass: "rft-active-bundle-modal"
  }, [_c('RftEditActiveBundleModal', {
    ref: "RftEditActiveBundleModal"
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }